<template>
  <v-col class="px-0 py-0">
    <Loader v-if="showLoader" />
    <v-expand-transition>
      <v-col
        class="px-0 py-0"
        v-if="!showLoader && $route.name == 'routesComponent'"
      >
        <v-row no-gutters align="center" style="margin: 20px 0px">
          <v-col class="py-0" style="padding-right: 10px">
            <statistic-card
              :numbers="statistic.routes"
              title="Активних маршрутів"
              subtitle="В продажі"
          /></v-col>
          <v-col class="py-0" style="padding-left: 10px">
            <statistic-card
              :numbers="statistic.schedules"
              title="Раз використано в рейсах"
              subtitle="наші квитки"
          /></v-col>
        </v-row>
        <v-row no-gutters align="center" style="margin-top: 30px">
          <v-col cols="4" class="px-0 py-0">
            <searchField
              label="Пошук маршруту"
              placeholder="Введіть тут.."
              @search="setSearchField"
            ></searchField>
          </v-col>
          <v-row no-gutters justify="end" v-if="$user_role == 'admin'">
            <submit-button
              style="height: 48px !important; width: 220px !important"
              @click="$router.push({ name: 'createRoute' })"
            >
              <v-icon style="margin-right: 8px">mdi-plus</v-icon>Додати
              маршрут</submit-button
            >
          </v-row>
        </v-row>
        <admin-routes-list
          :routes="routesList"
          :loading="loading"
          @getRoutes="(showLoader = true), getRoutes()"
          @editRoute="setEditRoute"
          @sortBy="setSortField"
        />
        <pagination
          :page="page"
          :quantityPage="quantityPage"
          @changePage="changePage"
        />
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <!-- <create-new-route-component
        v-if="isCreateNewRoute"
        :isEdit="isEdit"
        :routeForEdit="routeForEdit"
        @back="(isCreateNewRoute = false), $emit('back')"
      /> -->
      <router-view v-if="$route.name !== 'routesComponent' && !showLoader" />
    </v-expand-transition>
  </v-col>
</template>

<script>
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import statisticCard from "../../UI/Cards/statisticCard.vue";
import AdminRoutesList from "./adminRoutesList.vue";
//import createNewRouteComponent from "./createNewRouteComponent.vue";
import searchField from "./../../UI/Fields/searchField.vue";
import routesService from "../../../requests/Admin/routesService";
import Loader from "../../UI/Loader.vue";
import Pagination from "../../UI/pagination.vue";
import debounce from "lodash/debounce";
export default {
  components: {
    statisticCard,
    SubmitButton,
    AdminRoutesList,
    //createNewRouteComponent,
    searchField,
    Loader,
    Pagination,
  },
  data: () => ({
    isCreateNewRoute: false,
    isEdit: false,
    showLoader: true,
    loading: false,
    routeForEdit: {},
    routesList: [],
    page: 1,
    quantityPage: 0,
    statistic: {
      routes: 0,
      schedules: 0,
    },
    search: "",
    sortBy: "",
    sortDesc: "",
  }),
  mounted() {
    this.getRoutes();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getRoutes();
    },
    setSortField(sortBy, sortDesc) {
      if (sortBy !== null && sortBy !== undefined && sortBy !== "") {
        this.sortBy = sortBy;
        this.sortDesc = sortDesc == true ? "DESC" : "ASC";
      }
      this.sortRoutes();
    },
    sortRoutes: debounce(function () {
      this.loading = true;
      this.getRoutes();
    }, 300),
    async getRoutes() {
      await routesService
        .getRouteForAdmin(this.page, this.search, this.sortBy, this.sortDesc)
        .then(async (res) => {
          if (res.status == "Success") {
            this.routesList = res.data;
            this.showLoader = false;
            if (
              this.routesList.findIndex((route) => route.status == "Active") !==
              -1
            ) {
              await routesService.getSymlinkForAdmin().then((res) => {
                if (res.status == "Success") {
                  let symlinks = [];
                  console.log(res);
                  Object.values(res.data).forEach((symlink) =>
                    symlinks.push(...symlink)
                  );
                  this.routesList = this.routesList.map((route) => {
                    route = {
                      ...route,
                      symlinks: symlinks.filter(
                        (symlink) => symlink.route_uuid == route.id
                      ),
                    };
                    return route;
                  });
                  this.quantityPage = res?.pagination?.meta?.last_page;
                  this.loading = false;
                }
              });
            }
          }
        });
      this.getRoutesStatistic();
    },
    setSearchField(search) {
      if (search !== "" && search !== undefined && search !== null) {
        this.search = `all[like]:${search}`;
      } else {
        this.search = "";
      }
      this.loading = true;
      this.getRoutes();
    },
    async getRoutesStatistic() {
      await routesService.getRouteStatistic().then((res) => {
        this.statistic = res.data;
      });
    },
    setEditRoute(route) {
      this.routeForEdit = route;
      this.$emit(
        "editRoute",
        `Маршрут <span style="color: #144FA9;">${
          route.departure?.translations.find(
            (translate) => translate.lang == "ua"
          ).name
        } - ${
          route.destination?.translations.find(
            (translate) => translate.lang == "ua"
          ).name
        }</span>`
      );
      this.isEdit = true;
      this.isCreateNewRoute = true;
    },
  },
  watch: {
    isCreateNewRoute: {
      handler() {
        this.$vuetify.goTo(0, 0);
      },
    },
    "$route.path": {
      handler() {
        if (this.$route.name == "routesComponent") {
          this.showLoader = true;
          this.getRoutes();
        }
      },
    },
  },
};
</script>

<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>